import { GetServerSidePropsContext } from "next";

import { statsig } from "@atlas/service/statsig";

import { WaitlistPage } from "../../pods/auth/signup/pages/WaitlistPage";

export const getServerSideProps = async (context: GetServerSidePropsContext) => {
  const orgId = context.query?.orgId as string | undefined;
  const disableSignups = await statsig.checkGate(
    { account_id: "" },
    "disable_signups_for_waitlist",
  );
  if (!disableSignups) return { redirect: { destination: "/", permanent: false } };

  return { props: { title: "Waitlist", orgId } };
};

export default WaitlistPage;
