import addrs from "email-addresses";

/**
 * Examples:
 *   justAddress("John Doe <jane-doe@foo.com>") === jane-doe@foo.com
 *   justAddress("jane-doe@foo.com") === jane-doe@foo.com
 *
 * @param addr email address, which could include full name
 * @returns just the email part of the address
 */
export const justAddress = (addr: string): string => {
  const parsedFrom = addrs.parseOneAddress(addr) as addrs.ParsedMailbox;
  if (parsedFrom?.address) {
    return parsedFrom.address;
  }
  return addr;
};

/**
 * Return true if the email is an internal corporate user.
 */
export function isInternal(email: string) {
  const suffixes = [
    "@crowdshield.com",
    "@crowdsield.com",
    "@atlasprotect.com",
    "@atlas.net",
    "@dataprivacy.com",
    "mailosaur.net",
  ];
  const prefixes = ["jeffpalm", "arnaudsm66", "madkisso", "crowdshielddevinbox"];

  if (suffixes.some((suffix) => email.includes(suffix))) return true;
  if (prefixes.some((prefix) => email.startsWith(prefix))) return true;

  return false;
}

export const EMAIL_REGEX = /[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-+]+\.[a-zA-Z]{2,63}$/;
// should be same as above regex, but matches at least one email
// plus any number of additional emails separated by commas
export const EMAIL_CSV_REGEX =
  // eslint-disable-next-line security/detect-unsafe-regex
  /[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-+]+\.[a-zA-Z]{2,63}(?:,?[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-+]+\.[a-zA-Z]{2,63})*/;

export const isValidEmail = (email: string) => {
  if (!EMAIL_REGEX.test(email)) return false;
  else if (email.endsWith(".con")) return false;

  return true;
};
export const formatEmail = (email: string) => email.trim().toLowerCase();
