import { cn } from "@atlas/ui/helpers";
import { TextInput, TextInputProps } from "@atlas/ui/TextInput";

export interface TextareaInputProps extends TextInputProps {
  rows?: number;
}

export const TextareaInput = ({
  label,
  required = false,
  optionalLabel = "optional",
  className,
  ...args
}: TextareaInputProps) => (
  <TextInput
    {...args}
    label={label}
    required={required}
    optionalLabel={optionalLabel}
    inputClassName={cn(
      "w-80 rounded border border-gray-light bg-gray-lighter py-2 text-black placeholder:text-gray dark:border-none dark:bg-primary-darkest dark:text-white dark:placeholder:text-gray-darker",
      className,
    )}
    variant="textarea"
    as="textarea"
  />
);
