import * as React from "react";

import { EMAIL_REGEX } from "@atlas/lib/email";

import { TextInput, TextInputProps } from "./TextInput";

export interface EmailInputProps extends Omit<TextInputProps, "type" | "pattern"> {
  label?: string;
}

const EmailInput = React.forwardRef<HTMLInputElement, EmailInputProps>(
  ({ label = "Email", onChange, required, ...props }, ref) => {
    const handleChange = React.useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange?.(e);
      },
      [onChange],
    );

    return (
      <TextInput
        type="email"
        pattern={EMAIL_REGEX.source}
        placeholder="hello@gmail.com"
        label={label}
        required={required}
        onChange={handleChange}
        ref={ref}
        {...props}
      />
    );
  },
);

EmailInput.displayName = "EmailInput";

export { EmailInput };
